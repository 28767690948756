import i18next from 'i18next';
import { toastr } from 'react-redux-toastr';
import { Workbox } from 'workbox-window';
import { Log } from './api/ApiClient';

declare global {
    const __DEV__: string;
}

export const registerServiceWorker = () => {
    if (__DEV__) {
        return unregisterAllServiceWorkers();
    }

    if ('serviceWorker' in navigator) {
        const wb = new Workbox('/service-worker.js');

        wb.addEventListener('message', (event) => {
            if (event.data.type === 'CACHE_UPDATED') {
                toastr.warning(
                    i18next.t('Common:NewVersionAvailable'),
                    i18next.t('Common:NewVersionAvailable_Message'),
                    {
                        timeOut: 0,
                    });
            }
        });

        wb.addEventListener('redundant', () => {
            console.error('The installing service worker became redundant.');
            unregisterAllServiceWorkers();
        });

        return wb.register()
            .catch((ex) => {
                logError(ex);
                throw ex;
            });
    }
    else {
        return Promise.resolve();
    }
}

export const updateServiceWorker = () => {
    return new Promise<void>((resolve, reject) => {
        try {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistration('/service-worker.js').then((reg) => {
                    if (reg) {
                        reg.update();
                    } else {
                        registerServiceWorker();
                    }
                    resolve();
                }).catch((ex) => {
                    logError(ex);
                    reject(ex);
                });
            }
        } catch (ex) {
            logError(ex);
            reject(ex);
        }
    });
}

export const unregisterAllServiceWorkers = () => {
    return new Promise<void>((resolve, reject) => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                if (registrations) {
                    const unregisterPromises = registrations.map(reg => reg.unregister());
                    Promise.all(unregisterPromises).then(() => resolve());
                } else {
                    resolve();
                }
            }).catch((ex) => {
                logError(ex);
                reject(ex);
            });
        }
    });
}

const logError = (error: Error) => {
    const content_ = JSON.stringify({
        message: error.message,
    } as Log);
    return fetch('/api/ErrorApi/LogJavaScriptError', {
        body: content_,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    });
}