import { connect } from 'react-redux';
import { AppThunkDispatch, ApplicationState } from 'store';
import { Selectors } from 'store/normalizr/selectors';
import { MetaData } from './MetaData';
import { IProps, IDispatchProps, ILangUrl, IOwnProps } from './MetaDataModels';
import { getAppContext } from 'store/appContext/thunk';
import { noop } from 'lodash-es';
import { getBaseUrl } from 'config/constants';
import { RoutePaths } from 'components/Routes/RoutePaths';
import i18n from 'i18next';
import { AppContext } from 'services/api/ApiClient';

const baseUrl = getBaseUrl();
const routes: Map<string, (lng: string) => string> = getRouteDict(RoutePaths);

const mapStateToProps = (state: ApplicationState, ownProps: IOwnProps): IProps => {
    const { currentUrl } = ownProps;
    const appContext = Selectors.getAppContext(state.appContext.appContextId, state);
    const languages = getSupportedLanguages(appContext);
    const metaRoute = getMetaRoute(languages, getUrl(currentUrl));
    return {
        facebookAppId: appContext?.facebookAppId || undefined,
        urls: metaRoute?.urls,
        routeTitle: metaRoute?.title,
    };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): IDispatchProps => {
    return {
        onInit: () => {
            dispatch(getAppContext()).catch(noop);
        },
    };
};

function getUrl(currentUrl: string): string {
    //This can happen with SSR (maybe not an issue on prod)
    return currentUrl === "/" ? `/${i18n.language}` : currentUrl;
}

function getRouteDict(object: typeof RoutePaths): Map<string, (lng: string) => string> {
    const values: Map<string, (lng: string) => string> = new Map();
    updateRouteDict(object, "route", "", values);
    return values;
}

function updateRouteDict(object: any, key: string, path: string, values: Map<string, (lng: string) => string>): void {
    Object.keys(object).some(function (k) {
        if (k === key && path && object["url"]) {
            const val: (lng: string) => string = object[k] as (lng: string) => string;
            if (val) values.set(path, val);
        }
        else if (object[k] && typeof object[k] === 'object') {
            updateRouteDict(object[k], key, path ? `${path}.${k}` : k, values);
        }
    });
}

interface IMetaRoute {
    title: string | undefined;
    route: (lng: string) => string;
    urls: Array<ILangUrl>;
}

function getMetaRoute(languages: Array<string>, currentUrl: string | undefined): IMetaRoute | undefined {
    let currentRoute: IMetaRoute | undefined = undefined;
    for (const [name, route] of routes.entries()) {
        try {
            if (RoutePaths.url(route) === currentUrl) {
                currentRoute = {
                    title: getTitle(name),
                    route,
                    urls: [],
                };
                break;
            }
        } catch{
            // Don't mind it, let's continue.
        }
    }

    if (languages) {
        i18n.loadLanguages(languages, undefined);
        languages.forEach(langIso2 => {
            if (currentRoute) {
                const routeUrl: string = RoutePaths.urlForLang(langIso2, currentRoute.route);
                currentRoute.urls.push({
                    lang: langIso2,
                    url: baseUrl + routeUrl,
                });
            }
        })
    }
    return currentRoute;
}

function getTitle(routeName: string) {
    if (routeName) {
        const routeTokens = routeName.split('.');
        const routeKey = routeTokens[routeTokens.length - 1];
        return `${i18n.t(`Sitemap:${routeKey}`)} | MesHôtes.com`;
    }
    return undefined;
}

function getSupportedLanguages(appContext: AppContext | undefined): Array<string> {
    let languages: Array<string> = [];
    if (appContext?.supportedLanguages) {
        languages = appContext.supportedLanguages.map(lng => lng?.twoLetterISOLanguageName?.toLowerCase() || "en");
    }
    return languages;
}

export const MetaDataContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MetaData);
