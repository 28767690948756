import * as React from 'react';
import { ManagePageLayout } from './ManagePageLayout';

export function withManagePageLayout<P>(WrappedComponent: React.ComponentType<P>) {
    class Layout extends React.Component {
        public render() {
            const CoercedWrappedComponent = WrappedComponent as React.ComponentType;
            return (
                <ManagePageLayout>
                    <CoercedWrappedComponent {...this.props} />
                </ManagePageLayout>
            );
        }
    }

    return Layout;
}
