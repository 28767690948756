import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { loadableReady } from '@loadable/component';
import { configureFontawesome } from 'config/fontawesome';
import { configureI18n } from 'config/i18n';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { hydrate, render, Renderer } from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { userManager } from 'services/AuthService/UserManager';
import { RouterService } from 'services/RouterService';
import { registerServiceWorker } from 'services/ServiceWorkerService';
import { ApplicationState } from 'store';
import { Routes } from './components/Routes';
import { configureStore } from './configureStore';
import { loadUser } from 'services/AuthService';

import './css/site.scss';

declare global {
    // tslint:disable-next-line: interface-name
    interface Window {
        initialReduxState?: ApplicationState;
    }
}

const history = RouterService.createBrowserHistory();
const initialState = window.initialReduxState;
const store = configureStore(history, initialState);

loadUser();

TagManager.initialize({
    gtmId: 'GTM-52PG382',
});

const App = (children: React.ReactNode) => (
    <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
            <ConnectedRouter history={history}>
                {children}
            </ConnectedRouter>
        </OidcProvider>
    </Provider>
);

// This function starts up the React app when it runs in a browser. It sets up the routing configuration and injects the app into a DOM element.
const renderApp = (domRenderer: Renderer = render) => {
    domRenderer(App((<Routes />)), document.getElementById('root'));
};

// Need to hydrate in dev, since using SSR, so for now just check if initialState exists
configureFontawesome();
configureI18n()
    .then(() => {
        if (initialState && !module.hot) {
            loadableReady(() => renderApp(hydrate));
        } else {
            renderApp(render);
        }
    });

registerServiceWorker();
