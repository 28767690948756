import classNames from 'classnames';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IManagePageLayoutProps } from './ManagePageLayoutModels';
import { SideMenu } from './SideMenu';

class ManagePageLayoutComponent extends React.PureComponent<IManagePageLayoutProps & WithTranslation> {
    public render() {
        const { t } = this.props;
        const classes = classNames(
            'd-flex flex-fill',
        );
        return (
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex flex-column justify-content-center w-100" style={{ maxWidth: '70em' }}>
                    <div className="d-flex flex-column rounded text-white p-3" style={{ backgroundColor: 'rgba(110, 110, 110, 0.4)' }}>
                        <h2>
                            {t('Account:Manage_account')}
                        </h2>
                    </div>
                    <div className="d-flex flex-wrap flex-sm-nowrap mt-3">
                        <SideMenu className="mr-3 mb-3" />
                        <ErrorBoundary>
                            <div className={classes}>
                                {this.props.children}
                            </div>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        );
    }
}

export const ManagePageLayout = withTranslation()(ManagePageLayoutComponent);
