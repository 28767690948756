
import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

const unloadedState = () => {
    return {
        features: {
            didInvalidate: true,
            isFetching: false,
        } as IRequest & { readonly purchasedFeatures?: number[] },
        roomPlans: {
            didInvalidate: true,
            isFetching: false,
        } as IRequest & { readonly purchasedRoomPlans?: number[] },
    } as const;
};

export type MyPurchasesState = ReturnType<typeof unloadedState>;

export const MyPurchasesReducer: AppReducer<MyPurchasesState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@MY_PURCHASES/GET_FEATURES_REQUEST':
            return {
                ...state,
                features: {
                    ...state.features,
                    isFetching: true,
                },
            };

        case '@MY_PURCHASES/GET_FEATURES_SUCCESS':
            return {
                ...state,
                features: {
                    ...state.features,
                    isFetching: false,
                    didInvalidate: false,
                    purchasedFeatures: action.purchasedFeatures,
                },
            };

        case '@MY_PURCHASES/GET_FEATURES_FAILURE':
            return {
                ...state,
                features: {
                    ...state.features,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@MY_PURCHASES/GET_ROOM_PLANS_REQUEST':
            return {
                ...state,
                roomPlans: {
                    ...state.roomPlans,
                    isFetching: true,
                },
            };

        case '@MY_PURCHASES/GET_ROOM_PLANS_SUCCESS':
            return {
                ...state,
                roomPlans: {
                    ...state.roomPlans,
                    isFetching: false,
                    didInvalidate: false,
                    purchasedRoomPlans: action.purchasedRoomPlans,
                },
            };

        case '@MY_PURCHASES/GET_ROOM_PLANS_FAILURE':
            return {
                ...state,
                roomPlans: {
                    ...state.roomPlans,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};
