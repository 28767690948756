import { TermType } from 'services/api/ApiClient';

export const createGetTermsRequestAction = (termType: TermType) => {
    return {
        type: '@LEGAL/GET_TERMS_REQUEST',
        termType,
    } as const;
};

export const createGetTermsRequestSuccessAction = (termType: TermType, termsId: string) => {
    return {
        type: '@LEGAL/GET_TERMS_SUCCESS',
        termType,
        termsId,
    } as const;
};

export const createGetTermsRequestFailureAction = (termType: TermType) => {
    return {
        type: '@LEGAL/GET_TERMS_FAILURE',
        termType,
    } as const;
};

export const createAcceptTermsRequestAction = (termType: TermType) => {
    return {
        type: '@LEGAL/ACCEPT_TERMS_REQUEST',
        termType,
    } as const;
};

export const createAcceptTermsRequestSuccessAction = (termType: TermType) => {
    return {
        type: '@LEGAL/ACCEPT_TERMS_SUCCESS',
        termType,
    } as const;
};

export const createAcceptTermsRequestFailureAction = (termType: TermType) => {
    return {
        type: '@LEGAL/ACCEPT_TERMS_FAILURE',
        termType,
    } as const;
};

export type LegalAction =
    | ReturnType<typeof createGetTermsRequestAction>
    | ReturnType<typeof createGetTermsRequestSuccessAction>
    | ReturnType<typeof createGetTermsRequestFailureAction>
    | ReturnType<typeof createAcceptTermsRequestAction>
    | ReturnType<typeof createAcceptTermsRequestSuccessAction>
    | ReturnType<typeof createAcceptTermsRequestFailureAction>;
