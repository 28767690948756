import i18n from 'i18next';
import { compile } from 'path-to-regexp';
import { getConfiguration } from 'config/constants';

export const RoutePaths = {
    About: {
        route: (lng: string) => `${RoutePaths.route()}/${t('Routing:About', lng)}`,
        url: () => RoutePaths.url(RoutePaths.About.route),
    },
    Account: {
        Error: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:Error', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.Error.route),
        },
        ForgottenPassword: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:ForgotPassword', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.ForgottenPassword.route),
        },
        Login: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:Login', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.Login.route),
        },
        Logout: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:LogOff', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.Logout.route),
        },
        Register: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:Register', lng)}`,
            url: (returnUrl?: string) => RoutePaths.url(RoutePaths.Account.Register.route) + (returnUrl ? `?returnUrl=${returnUrl}` : ''),
        },
        ResetPassword: {
            route: (lng: string) => `${RoutePaths.Account.route()}/${t('Routing:PasswordReset', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Account.ResetPassword.route),
        },
        route: () => `${RoutePaths.route()}`,
    },
    Blog: {
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotes}${RoutePaths.route()}/${t('Routing:Blog', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Blog.route),
    },
    Callback: {
        route: (lng: string) => `/${t('Routing:Callback', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Callback.route),
    },
    Contact: {
        route: (lng: string) => `${RoutePaths.route()}/${t('Routing:Contact', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Contact.route),
    },
    Errors: {
        NotFound: {
            route: () => {
                return '*';
            },
            url: () => RoutePaths.url(RoutePaths.Errors.NotFound.route),
        },
    },
    Events: {
        Create: {
            route: (lng: string) => `${RoutePaths.Events.route(lng)}/${t('Routing:Create', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Events.Create.route),
        },
        List: {
            route: (lng: string) => `${RoutePaths.Events.route(lng)}/:eventId(\\d+)?`,
            url: (eventId?: number, customUrl?: string, demoId?: string) => RoutePaths.url(RoutePaths.Events.List.route, { eventId, customUrl, demoId }),
        },
        route: (lng: string) => `${getConfiguration().externalLinks.mesHotes}${RoutePaths.route()}/${t('Routing:Events', lng)}`,
    },
    Forum: {
        route: () => `${getConfiguration().externalLinks.mesHotesForum}`,
        url: () => RoutePaths.url(RoutePaths.Forum.route),
    },
    Help: {
        route: (lng: string) => `${RoutePaths.route()}/${t('Routing:Help', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Help.route),
    },
    Legal: {
        LegalNotices: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:LegalNotices', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.LegalNotices.route),
        },
        Privacy: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:Privacy', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.Privacy.route),
        },
        TermsOfSales: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:TermsOfSales', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfSales.route),
        },
        TermsOfSalesPro: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:Pro', lng)}/${t('Routing:TermsOfSales', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfSalesPro.route),
        },
        TermsOfUse: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:TermsOfUse', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfUse.route),
        },
        TermsOfUsePro: {
            route: (lng: string) => `${RoutePaths.Legal.route(lng)}/${t('Routing:Pro', lng)}/${t('Routing:TermsOfUse', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Legal.TermsOfUsePro.route),
        },
        route: (lng: string) => `${RoutePaths.route()}/${t('Routing:Legal', lng)}`,
    },
    Manage: {
        ChangePassword: {
            route: (lng: string) => `${RoutePaths.Manage.route(lng)}/${t('Routing:ChangePassword', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.ChangePassword.route),
        },
        DeleteAccount: {
            route: (lng: string) => `${RoutePaths.Manage.route(lng)}/${t('Routing:Delete', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.DeleteAccount.route),
        },
        EmailSubscription: {
            route: (lng: string) => `${RoutePaths.Manage.route(lng)}/${t('Routing:EmailSubscription', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.EmailSubscription.route),
        },
        ExternalLogins: {
            route: (lng: string) => `${RoutePaths.Manage.route(lng)}/${t('Routing:ExternalLogins', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.ExternalLogins.route),
        },
        MyPurchases: {
            route: (lng: string) => `${RoutePaths.Manage.route(lng)}/${t('Routing:MyPurchases', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.MyPurchases.route),
        },
        PaymentInformation: {
            route: (lng: string) => `${RoutePaths.Manage.route(lng)}/${t('Routing:PaymentInformation', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Manage.PaymentInformation.route),
        },
        Profile: {
            route: () => `${RoutePaths.route()}`,
            url: () => RoutePaths.url(RoutePaths.Manage.Profile.route),
        },
        route: (lng: string) => `${RoutePaths.route()}/${t('Routing:Manage', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Manage.route),
    },
    Maps: {
        Map: {
            route: () => `${RoutePaths.Maps.route()}/:mapCenter?`,
            url: (mapCenter?: string) => RoutePaths.url(RoutePaths.Maps.Map.route, { mapCenter }),
        },
        route: () => `${getConfiguration().externalLinks.mesHotesMaps}${RoutePaths.route()}`,
        url: () => RoutePaths.url(RoutePaths.Maps.route),
    },
    Press: {
        route: (lng: string) => `${RoutePaths.route()}/${t('Routing:Press', lng)}`,
        url: () => RoutePaths.url(RoutePaths.Press.route),
    },
    Pro: {
        CatererDashboard: {
            route: (lng: string) => `${getConfiguration().externalLinks.mesHotesPro}${RoutePaths.route()}/${t('Routing:Caterer', lng)}/${t('Routing:Dashboard', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Pro.CatererDashboard.route),
        },
        ReceptionRoomManagerDashboard: {
            route: (lng: string) => `${getConfiguration().externalLinks.mesHotesPro}${RoutePaths.route()}/${t('Routing:ProDomain', lng)}/${t('Routing:Dashboard', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Pro.ReceptionRoomManagerDashboard.route),
        },
        WeddingPlannerDashboard: {
            route: (lng: string) => `${getConfiguration().externalLinks.mesHotesPro}${RoutePaths.route()}/${t('Routing:WeddingPlanner', lng)}/${t('Routing:Dashboard', lng)}`,
            url: () => RoutePaths.url(RoutePaths.Pro.WeddingPlannerDashboard.route),
        },
    },
    ServicesPricing: {
        route: (lng: string) => `${RoutePaths.route()}/${t('Routing:ServicesPricing', lng)}`,
        url: () => RoutePaths.url(RoutePaths.ServicesPricing.route),
    },

    route: () => {
        return '/:lang([a-zA-Z]{2})';
    },

    url: (route: (lng: string) => string, parameters?: { [id: string]: unknown }) => {
        const { language } = i18n;
        return RoutePaths.urlForLang(language, route, parameters).toLowerCase();
    },

    urlForLang: (language: string, route: (lng: string) => string, parameters?: { [id: string]: unknown }) => {
        let localizedRoute = route(language);
        const routingEvent: string = i18n.t('Routing:Events', { lng: language });
        if (parameters?.demoId) {
            localizedRoute = localizedRoute.replace(`/${routingEvent}/:eventId(\\d+)`, `/demo/:demoId`);
        }
        else if (parameters?.customUrl) {
            localizedRoute = localizedRoute.replace(`/${routingEvent}/:eventId(\\d+)`, `/public/:customUrl`);
        }

        if (localizedRoute.startsWith('http')) {
            localizedRoute = localizedRoute.replace(/(https?):\/\/([a-z.]*):?([\d]+)/i, '$1\\://$2\\:$3');
            localizedRoute = localizedRoute.replace(/(https?):\/\//, '$1\\://');
        }

        return compile(localizedRoute)({
            lang: language,
            ...parameters,
        });
    },
};

const t = (key: string, lng: string) => i18n.t(key, { lng });
