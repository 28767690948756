import { noop } from 'lodash-es';
import { connect } from 'react-redux';
import { AppThunkDispatch, ApplicationState } from 'store';
import { getAppContext } from 'store/appContext/thunk';
import { Selectors } from 'store/normalizr/selectors';
import { TermsConsent } from './TermsConsent';
import { IDispatchProps, IProps } from './TermsConsentModels';
import { acceptTerms } from 'store/legal/thunk';

const mapStateToProps = (state: ApplicationState): IProps => {
    const appContext = Selectors.getAppContext(state.appContext.appContextId, state);
    return {
        termsStatus: appContext?.termsStatus,
    };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): IDispatchProps => {
    return {
        onInit: () => {
            dispatch(getAppContext()).catch(noop);
        },
        onAcceptTerms: (termType) => {
            dispatch(acceptTerms(termType))
                .then(() => {
                    dispatch(getAppContext()).catch(noop);
                });
        },
    };
};

export const TermsConsentContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TermsConsent);
