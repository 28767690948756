import { noop } from 'lodash-es';
import { connect } from 'react-redux';
import { ApplicationState, AppThunkDispatch } from 'store';
import { getAppContext } from 'store/appContext/thunk';
import { signIn } from 'services/AuthService';
import { AuthorizedRoute } from './AuthorizedRoute';
import { IDispatchProps, IProps } from './AuthorizedRouteModels';
import { Selectors } from 'store/normalizr/selectors';

const mapStateToProps = (state: ApplicationState): IProps => {
    const appContext = Selectors.getAppContext(state.appContext.appContextId, state);
    return {
        isAuthenticated: !!appContext?.loggedUser || !!state.oidc.user,
    };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): IDispatchProps => {
    return {
        onInit: (isAuthenticated) => {
            dispatch(getAppContext()).catch(noop);
            if (!isAuthenticated) {
                signIn();
            }
        },
    };
};

export const AuthorizedRouteContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AuthorizedRoute);
