
import { AppReducer } from 'store';

const unloadedState = () => {
    return {
        isFetching: false as boolean,
        isFetchingError: false as boolean,
        isSigningIn: false as boolean,
        isSigningOut: false as boolean,
        callbackReturnUrl: undefined as string | undefined,
    } as const;
};

export type AuthenticationState = ReturnType<typeof unloadedState>;

export const AuthenticationReducer: AppReducer<AuthenticationState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@AUTHENTICATION/SIGN_IN_REQUEST':
        case '@AUTHENTICATION/SIGN_OUT_REQUEST':
        case '@AUTHENTICATION/REGISTER_REQUEST':
        case '@AUTHENTICATION/FORGOT_PASSWORD_REQUEST':
        case '@AUTHENTICATION/RESET_PASSWORD_REQUEST':
            return {
                ...state,
                isFetching: true,
                callbackReturnUrl: undefined,
            };
        case '@AUTHENTICATION/INIT_SIGN_IN':
            return {
                ...state,
                isSigningIn: true,
            };

        case '@AUTHENTICATION/INIT_SIGN_IN_COMPLETE':
            return {
                ...state,
                isSigningIn: false,
            };

        case '@AUTHENTICATION/INIT_SIGN_OUT':
            return {
                ...state,
                isSigningOut: true,
            };

        case '@AUTHENTICATION/SIGN_IN_SUCCESS':
        case '@AUTHENTICATION/SIGN_OUT_SUCCESS':
        case '@AUTHENTICATION/REGISTER_SUCCESS':
        case '@AUTHENTICATION/FORGOT_PASSWORD_SUCCESS':
        case '@AUTHENTICATION/RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                isFetching: false,
                isSigningOut: false,
            };

        case '@AUTHENTICATION/SIGN_IN_FAILURE':
        case '@AUTHENTICATION/SIGN_OUT_FAILURE':
        case '@AUTHENTICATION/REGISTER_FAILURE':
        case '@AUTHENTICATION/FORGOT_PASSWORD_FAILURE':
        case '@AUTHENTICATION/RESET_PASSWORD_FAILURE':
            return {
                ...state,
                isFetching: false,
                callbackReturnUrl: undefined,
                isSigningOut: false,
            };

        case '@AUTHENTICATION/GET_ERROR_REQUEST':
            return {
                ...state,
                isFetchingError: true,
            };

        case '@AUTHENTICATION/GET_ERROR_SUCCESS':
            return {
                ...state,
                isFetchingError: false,
            };

        case '@AUTHENTICATION/GET_ERROR_FAILURE':
            return {
                ...state,
                isFetchingError: false,
            };

        case '@AUTHENTICATION/GET_CALLBACK_RETURN_URL_SUCCESS':
            return {
                ...state,
                callbackReturnUrl: action.returnUrl,
            };

        default:
            return state;
    }
};
