
import { AppReducer } from 'store';

const unloadedState = () => {
    return {
        didInvalidate: true as boolean,
        isFetching: false as boolean,
        isUpdating: false as boolean,
    } as const;
};

export type PaymentInformationState = ReturnType<typeof unloadedState>;

export const PaymentInformationReducer: AppReducer<PaymentInformationState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@PAYMENT_INFORMATION/GET_PAYMENT_INFORMATION_REQUEST':
            return {
                ...state,
                isFetching: true,
            };

        case '@PAYMENT_INFORMATION/GET_PAYMENT_INFORMATION_SUCCESS':
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
            };

        case '@PAYMENT_INFORMATION/GET_PAYMENT_INFORMATION_FAILURE':
            return {
                ...state,
                isFetching: false,
                didInvalidate: true,
            };

        case '@PAYMENT_INFORMATION/UPDATE_PAYMENT_INFORMATION_REQUEST':
            return {
                ...state,
                isUpdating: true,
            };

        case '@PAYMENT_INFORMATION/UPDATE_PAYMENT_INFORMATION_SUCCESS':
            return {
                ...state,
                isUpdating: false,
                didInvalidate: true,
            };

        case '@PAYMENT_INFORMATION/UPDATE_PAYMENT_INFORMATION_FAILURE':
            return {
                ...state,
                isUpdating: false,
            };

        default:
            return state;
    }
};
