export const createSignInRequestAction = () => {
    return {
        type: '@AUTHENTICATION/SIGN_IN_REQUEST',
    } as const;
};

export const createSignInRequestSuccessAction = () => {
    return {
        type: '@AUTHENTICATION/SIGN_IN_SUCCESS',
    } as const;
};

export const createSignInRequestFailureAction = () => {
    return {
        type: '@AUTHENTICATION/SIGN_IN_FAILURE',
    } as const;
};

export const createInitSignInAction = () => {
    return {
        type: '@AUTHENTICATION/INIT_SIGN_IN',
    } as const;
};

export const createInitSignInCompleteAction = () => {
    return {
        type: '@AUTHENTICATION/INIT_SIGN_IN_COMPLETE',
    } as const;
};

export const createInitSignOutAction = () => {
    return {
        type: '@AUTHENTICATION/INIT_SIGN_OUT',
    } as const;
};

export const createSignOutRequestAction = () => {
    return {
        type: '@AUTHENTICATION/SIGN_OUT_REQUEST',
    } as const;
};

export const createSignOutRequestSuccessAction = () => {
    return {
        type: '@AUTHENTICATION/SIGN_OUT_SUCCESS',
    } as const;
};

export const createSignOutRequestFailureAction = () => {
    return {
        type: '@AUTHENTICATION/SIGN_OUT_FAILURE',
    } as const;
};

export const createGetErrorRequestAction = () => {
    return {
        type: '@AUTHENTICATION/GET_ERROR_REQUEST',
    } as const;
};

export const createGetErrorRequestSuccessAction = () => {
    return {
        type: '@AUTHENTICATION/GET_ERROR_SUCCESS',
    } as const;
};

export const createGetErrorRequestFailureAction = () => {
    return {
        type: '@AUTHENTICATION/GET_ERROR_FAILURE',
    } as const;
};

export const createGetCallbackReturnUrlAction = () => {
    return {
        type: '@AUTHENTICATION/GET_CALLBACK_RETURN_URL_REQUEST',
    } as const;
};

export const createGetCallbackReturnUrlSuccessAction = (returnUrl: string) => {
    return {
        type: '@AUTHENTICATION/GET_CALLBACK_RETURN_URL_SUCCESS',
        returnUrl,
    } as const;
};

export const createGetCallbackReturnUrlFailureAction = () => {
    return {
        type: '@AUTHENTICATION/GET_CALLBACK_RETURN_URL_FAILURE',
    } as const;
};

export const createRegisterRequestAction = () => {
    return {
        type: '@AUTHENTICATION/REGISTER_REQUEST',
    } as const;
};

export const createRegisterRequestSuccessAction = () => {
    return {
        type: '@AUTHENTICATION/REGISTER_SUCCESS',
    } as const;
};

export const createRegisterRequestFailureAction = () => {
    return {
        type: '@AUTHENTICATION/REGISTER_FAILURE',
    } as const;
};

export const createForgotPasswordRequestAction = () => {
    return {
        type: '@AUTHENTICATION/FORGOT_PASSWORD_REQUEST',
    } as const;
};

export const createForgotPasswordRequestSuccessAction = () => {
    return {
        type: '@AUTHENTICATION/FORGOT_PASSWORD_SUCCESS',
    } as const;
};

export const createForgotPasswordRequestFailureAction = () => {
    return {
        type: '@AUTHENTICATION/FORGOT_PASSWORD_FAILURE',
    } as const;
};

export const createResetPasswordRequestAction = () => {
    return {
        type: '@AUTHENTICATION/RESET_PASSWORD_REQUEST',
    } as const;
};

export const createResetPasswordRequestSuccessAction = () => {
    return {
        type: '@AUTHENTICATION/RESET_PASSWORD_SUCCESS',
    } as const;
};

export const createResetPasswordRequestFailureAction = () => {
    return {
        type: '@AUTHENTICATION/RESET_PASSWORD_FAILURE',
    } as const;
};

export type AuthenticationAction =
    | ReturnType<typeof createSignInRequestAction>
    | ReturnType<typeof createSignInRequestSuccessAction>
    | ReturnType<typeof createSignInRequestFailureAction>
    | ReturnType<typeof createInitSignInAction>
    | ReturnType<typeof createInitSignInCompleteAction>
    | ReturnType<typeof createInitSignOutAction>
    | ReturnType<typeof createSignOutRequestAction>
    | ReturnType<typeof createSignOutRequestSuccessAction>
    | ReturnType<typeof createSignOutRequestFailureAction>
    | ReturnType<typeof createGetErrorRequestAction>
    | ReturnType<typeof createGetErrorRequestSuccessAction>
    | ReturnType<typeof createGetErrorRequestFailureAction>
    | ReturnType<typeof createGetCallbackReturnUrlAction>
    | ReturnType<typeof createGetCallbackReturnUrlSuccessAction>
    | ReturnType<typeof createGetCallbackReturnUrlFailureAction>
    | ReturnType<typeof createRegisterRequestAction>
    | ReturnType<typeof createRegisterRequestSuccessAction>
    | ReturnType<typeof createRegisterRequestFailureAction>
    | ReturnType<typeof createForgotPasswordRequestAction>
    | ReturnType<typeof createForgotPasswordRequestSuccessAction>
    | ReturnType<typeof createForgotPasswordRequestFailureAction>
    | ReturnType<typeof createResetPasswordRequestAction>
    | ReturnType<typeof createResetPasswordRequestSuccessAction>
    | ReturnType<typeof createResetPasswordRequestFailureAction>;
