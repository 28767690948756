import { normalize } from 'normalizr';
import { LegalApi } from 'services/api';
import { LegalTerms, TermType } from 'services/api/ApiClient';
import { ApiSchema } from 'services/api/ApiSchema';
import { Logger } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { SSRService } from 'services/ServerSideRenderingService';
import { AppThunkAction } from 'store';
import { mergeEntities } from 'store/normalizr/actions';
import {
    createGetTermsRequestAction,
    createGetTermsRequestFailureAction,
    createGetTermsRequestSuccessAction,
    createAcceptTermsRequestAction,
    createAcceptTermsRequestFailureAction,
    createAcceptTermsRequestSuccessAction,
} from './actions';

export const getTerms = (termType: TermType): AppThunkAction<LegalTerms | null> => (dispatch, getState) => {
    const state = getState();

    const request = state.legal.requests[termType];
    if (request && (request.isFetching || !request.didInvalidate)) {
        return PromiseStore.get('getTerms', termType);
    }

    dispatch(createGetTermsRequestAction(termType));

    const fetchTask = LegalApi
        .terms(termType)
        .then((data) => {
            const normalizedData = normalize(data, ApiSchema.LegalTermsSchema);
            dispatch(mergeEntities(normalizedData.entities));
            dispatch(createGetTermsRequestSuccessAction(termType, normalizedData.result));
            return data;
        })
        .catch((error: Error) => {
            dispatch(createGetTermsRequestFailureAction(termType));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'getTerms');
    PromiseStore.set(fetchTask, 'getTerms', termType);

    return fetchTask;
};

export const acceptTerms = (termType: TermType): AppThunkAction => (dispatch, getState) => {
    const state = getState();

    const request = state.legal.acceptRequests[termType];
    if (request) {
        return PromiseStore.get('acceptTerms', termType);
    }

    dispatch(createAcceptTermsRequestAction(termType));

    const fetchTask = LegalApi
        .acceptTerms(termType)
        .then(() => {
            dispatch(createAcceptTermsRequestSuccessAction(termType));
        })
        .catch((error: Error) => {
            dispatch(createAcceptTermsRequestFailureAction(termType));
            Logger.logError(error);
            throw error;
        });

    SSRService.addTask(fetchTask, 'acceptTerms');
    PromiseStore.set(fetchTask, 'acceptTerms', termType);

    return fetchTask;
};
