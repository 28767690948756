
import { AppReducer } from 'store';

export enum Provider {
    Facebook = 'Facebook',
    Google = 'Google',
    Microsoft = 'Microsoft',
    Twitter = 'Twitter',
}


const unloadedState = () => {
    return {
        isFetching: false as boolean,
        didInvalidate: true as boolean,
        isUpdatingPassword: false as boolean,
        isUpdatingSubscriptions: false as boolean,
        isFetchingPersonalData: false as boolean,
        isDeleting: false as boolean,
        isCancelingDeleteRequest: false as boolean,
        removeLoginsRequest: {} as { readonly [provider: string]: boolean },
    } as const;
};

export type ProfileState = ReturnType<typeof unloadedState>;

export const ProfileReducer: AppReducer<ProfileState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@PROFILE/GET_PROFILE_REQUEST':
            return {
                ...state,
                isFetching: true,
            };

        case '@PROFILE/GET_PROFILE_SUCCESS':
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
            };

        case '@PROFILE/GET_PROFILE_FAILURE':
            return {
                ...state,
                isFetching: false,
                didInvalidate: true,
            };

        case '@PROFILE/CHANGE_PASSWORD_REQUEST':
            return {
                ...state,
                isUpdatingPassword: true,
            };

        case '@PROFILE/CHANGE_PASSWORD_SUCCESS':
            return {
                ...state,
                isUpdatingPassword: false,
                didInvalidate: true,
            };

        case '@PROFILE/CHANGE_PASSWORD_FAILURE':
            return {
                ...state,
                isUpdatingPassword: false,
            };

        case '@PROFILE/UPDATE_SUBSCRIPTIONS_REQUEST':
            return {
                ...state,
                isUpdatingSubscriptions: true,
            };

        case '@PROFILE/UPDATE_SUBSCRIPTIONS_SUCCESS':
            return {
                ...state,
                isUpdatingSubscriptions: false,
                didInvalidate: true,
            };

        case '@PROFILE/UPDATE_SUBSCRIPTIONS_FAILURE':
            return {
                ...state,
                isUpdatingSubscriptions: false,
            };

        case '@PROFILE/DOWNLOAD_PERSONAL_DATA_REQUEST':
            return {
                ...state,
                isFetchingPersonalData: true,
            };

        case '@PROFILE/DOWNLOAD_PERSONAL_DATA_SUCCESS':
        case '@PROFILE/DOWNLOAD_PERSONAL_DATA_FAILURE':
            return {
                ...state,
                isFetchingPersonalData: false,
            };

        case '@PROFILE/DELETE_ACCOUNT_REQUEST':
            return {
                ...state,
                isDeleting: true,
            };

        case '@PROFILE/DELETE_ACCOUNT_SUCCESS':
            return {
                ...state,
                isDeleting: false,
                didInvalidate: true,
            };
        case '@PROFILE/DELETE_ACCOUNT_FAILURE':
            return {
                ...state,
                isDeleting: false,
            };

        case '@PROFILE/CANCEL_DELETE_ACCOUNT_REQUEST':
            return {
                ...state,
                isCancelingDeleteRequest: true,
            };

        case '@PROFILE/CANCEL_DELETE_ACCOUNT_SUCCESS':
            return {
                ...state,
                isCancelingDeleteRequest: false,
                didInvalidate: true,
            };

        case '@PROFILE/CANCEL_DELETE_ACCOUNT_FAILURE':
            return {
                ...state,
                isCancelingDeleteRequest: false,
            };

        case '@PROFILE/REMOVE_LOGIN_REQUEST':
            return {
                ...state,
                removeLoginsRequest: {
                    ...state.removeLoginsRequest,
                    [action.provider]: true,
                },
            };

        case '@PROFILE/REMOVE_LOGIN_SUCCESS':
            return {
                ...state,
                removeLoginsRequest: {
                    ...state.removeLoginsRequest,
                    [action.provider]: false,
                },
                didInvalidate: true,
            };

        case '@PROFILE/REMOVE_LOGIN_FAILURE':
            return {
                ...state,
                removeLoginsRequest: {
                    ...state.removeLoginsRequest,
                    [action.provider]: false,
                },
            };

        default:
            return state;
    }
};
