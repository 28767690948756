import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface ITermsRequest extends IRequest {
    readonly termsId?: string;
}

const unloadedState = () => {
    return {
        requests: {} as { readonly [termType: string]: ITermsRequest },
        acceptRequests: {} as { readonly [termType: string]: boolean },
    } as const;
};

export type LegalState = ReturnType<typeof unloadedState>;

export const LegalReducer: AppReducer<LegalState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@LEGAL/GET_TERMS_REQUEST':
            return {
                ...state,
                requests: {
                    ...state.requests,
                    [action.termType]: {
                        ...state.requests[action.termType],
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@LEGAL/GET_TERMS_SUCCESS':
            return {
                ...state,
                requests: {
                    ...state.requests,
                    [action.termType]: {
                        ...state.requests[action.termType],
                        didInvalidate: false,
                        isFetching: false,
                        termsId: action.termsId,
                    },
                },
            };

        case '@LEGAL/GET_TERMS_FAILURE':
            return {
                ...state,
                requests: {
                    ...state.requests,
                    [action.termType]: {
                        ...state.requests[action.termType],
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@LEGAL/ACCEPT_TERMS_REQUEST':
            return {
                ...state,
                acceptRequests: {
                    ...state.acceptRequests,
                    [action.termType]: true,
                },
            };

        case '@LEGAL/ACCEPT_TERMS_SUCCESS':
        case '@LEGAL/ACCEPT_TERMS_FAILURE':
            return {
                ...state,
                acceptRequests: {
                    ...state.acceptRequests,
                    [action.termType]: false,
                },
            };

        default:
            return state;
    }
};
