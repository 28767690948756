import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { boundMethod } from 'autobind-decorator';
import * as React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Language as LanguageModel } from 'services/api/ApiClient';
import { ILanguageProps } from './LanguageModels';

export class Language extends React.PureComponent<ILanguageProps> {
    public componentDidMount() {
        const { onInit, requestedLanguage } = this.props;
        onInit(requestedLanguage);
    }

    public componentDidUpdate(prev: ILanguageProps) {
        const { onInit, requestedLanguage } = this.props;
        const { requestedLanguage: prevRequestedLanguage } = prev;
        if (requestedLanguage
            && requestedLanguage.twoLetterISOLanguageName
            && (!prevRequestedLanguage || prevRequestedLanguage.twoLetterISOLanguageName !== requestedLanguage.twoLetterISOLanguageName)) {
            onInit(requestedLanguage);
        }
    }

    public render() {
        const { requestedLanguage, supportedLanguages } = this.props;
        const availableLanguages = supportedLanguages.filter((l) => !requestedLanguage || l.twoLetterISOLanguageName !== requestedLanguage.twoLetterISOLanguageName);
        return requestedLanguage ? (
            <UncontrolledDropdown direction="up">
                <DropdownToggle caret={true} color="">
                    <FontAwesomeIcon icon={faGlobe} />
                    {requestedLanguage.neutralNativeName}
                </DropdownToggle>
                <DropdownMenu>
                    {availableLanguages.map((l) => this.renderLanguageItem(l))}
                </DropdownMenu>
            </UncontrolledDropdown>
        ) : null;
    }

    public renderLanguageItem(availableLanguage: LanguageModel) {
        const { requestedLanguage } = this.props;
        const key = availableLanguage.twoLetterISOLanguageName || '-';
        return (
            <DropdownItem
                key={key}
                active={availableLanguage.twoLetterISOLanguageName === requestedLanguage?.twoLetterISOLanguageName}
                onClick={this.handleChangeLanguage.bind(this, availableLanguage)}
            >
                {availableLanguage.neutralNativeName}
            </DropdownItem>
        );
    }

    @boundMethod
    private handleChangeLanguage(language: LanguageModel, e: React.MouseEvent<HTMLAnchorElement>): void {
        const { onChangeLanguage, isAuthenticated } = this.props;
        e.preventDefault();
        if (language) {
            onChangeLanguage(language, isAuthenticated);
        }
    }
}
