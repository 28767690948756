import { windowIfDefined } from 'services/WindowService';
import { Configuration as ConfigurationLocal } from './constants.local';
import { Configuration as ConfigurationPreProd } from './constants.pre-prod';
import { Configuration } from './constants.prod';

export const apiVersion = '2.2.0.9';
export const publicPath = '/';

let baseApiUrl = '';
export const setBaseApiUrl = (url: string) => {
    baseApiUrl = url;
};
export const getBaseApiUrl = () => baseApiUrl;

const location = windowIfDefined && windowIfDefined.location;
const protocol = (location && location.protocol) || 'https:';
const hostname = (location && location.hostname) || 'localhost';
const port = location && location.port;
let baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
export const setBaseUrl = (url: string) => {
    baseUrl = url;
};
export const getBaseUrl = () => baseUrl;

export const getConfiguration = () => {
    const env = getEnvironment(baseUrl);

    switch (env) {
        case 'local':
            return ConfigurationLocal;

        case 'pre-prod':
            return ConfigurationPreProd;

        default:
            return Configuration;
    }
};

const getEnvironment = (url: string) => {
    const domain = getDomain(url, true);

    if (domain.startsWith('localhost')) {
        return 'local';
    }

    if (domain.startsWith('test')) {
        return 'pre-prod';
    }

    return 'prod';
};

const getDomain = (url: string, subdomain: boolean) => {
    subdomain = subdomain || false;

    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    if (!subdomain) {
        url = url.split('.')
            .slice(url.length - 2)
            .join('.');
    }

    if (url.indexOf('/') !== -1) {
        return url.split('/')[0];
    }

    return url;
};
