import i18next from 'i18next';
import { replace, noop } from 'lodash-es';
import { middlewareData } from 'middlewares/actionsRecorderMiddleware';
import { toastr } from 'react-redux-toastr';
import { ErrorApi } from 'services/api';
import { ReduxState, SwaggerException } from 'services/api/ApiClient';
import { documentIfDefined, windowIfDefined } from 'services/WindowService';
import { fromError } from 'stacktrace-js';

let unloaded = false;
if (windowIfDefined) {
    windowIfDefined.addEventListener('beforeunload', () => {
        unloaded = true;
    });
}

export class Logger {
    public static logError(error: Error, stacktrace?: string, displayError = false) {
        if (!error) {
            return;
        }

        if (SwaggerException.isSwaggerException(error)) {
            return;
        }

        const message = error.message || error.name;
        const fileName = documentIfDefined && documentIfDefined.location;
        const logMessage = `${message}\n  at document path ${fileName?.pathname || ''}`;

        if (error.name === 'AbortError' || this.doNotProcessMessage(message)) {
            return;
        }

        if (!stacktrace) {
            const getStackPromise = fromError(error);
            getStackPromise
                .then((stackframes: StackTrace.StackFrame[]) => {
                    this.sendError(
                        logMessage,
                        JSON.stringify(stackframes),
                        middlewareData.getReduxRecord());
                })
                .catch((err: Error) => {
                    const errorMessage = 'Logger.logError failed to get stacktrace: '
                        + err.message
                        + '\n\nOriginal error:\n' + logMessage;

                    this.sendError(
                        errorMessage,
                        JSON.stringify(stacktrace),
                        middlewareData.getReduxRecord());
                });
        } else {
            this.sendError(
                logMessage,
                JSON.stringify(stacktrace),
                middlewareData.getReduxRecord());
        }

        if (displayError && !unloaded) {
            toastr.error(i18next.t('Common:Error'), message);
        }
    }

    private static doNotProcessMessage(error: string) {
        if (error) {
            const errorCaseInsentive = error.toLowerCase();
            if (errorCaseInsentive.indexOf('blocked a frame with origin') > -1) {
                return true;
            } else if (errorCaseInsentive.startsWith('script error')) {
                return true;
            } else if (errorCaseInsentive.startsWith('document.getelementsbyclassname.tostring is not a function')) {
                return true;
            } else if (errorCaseInsentive.startsWith('can\'t find variable: requestanimationframe')) {
                return true;
            }
        }
        return false;
    }

    private static basePropertyOf(object: { [id: string]: string }) {
        return (key: string) => {
            return object == null ? '' : object[key];
        };
    }

    private static sendError(message: string, stacktrace: string, reduxState: ReduxState) {
        const replacement = {
            '<': '&lt;',
            '>': '&gt;',
        };
        const sanitizedMessage = replace(message, /[<>]/g, this.basePropertyOf(replacement));
        const sanitizedStacktrace = replace(stacktrace, /[<>]/g, this.basePropertyOf(replacement));
        const sanitizedPayload = reduxState.payload ?
            replace(reduxState.payload, /[<>]/g, this.basePropertyOf(replacement)) : undefined;
        const sanitizedPreloadedState = reduxState.preloadedState ?
            replace(reduxState.preloadedState, /[<>]/g, this.basePropertyOf(replacement)) : undefined;
        ErrorApi.logJavaScriptError({
            message: sanitizedMessage,
            stacktrace: sanitizedStacktrace,
            reduxState: {
                payload: sanitizedPayload,
                preloadedState: sanitizedPreloadedState,
            },
        })
            .catch(noop);
    }
}
