import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons/faShoppingbag';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons/faMailBulk';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { RoutePaths } from 'components/Routes/RoutePaths';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ISideMenuProps } from './SideMenuModels';
import { NavLink } from 'react-router-dom';

class SideMenuComponent extends React.PureComponent<ISideMenuProps & WithTranslation> {
    public componentDidMount() {
        const { onInit } = this.props;
        onInit();
    }

    public render() {
        const { t, className } = this.props;
        const classes = classNames(
            'd-flex flex-column flex-fill rounded text-white p-3',
            className);

        return (
            <nav className={classes} style={{ backgroundColor: 'rgba(110, 110, 110, 0.4)', maxWidth: '20em' }}>
                <ul className="nav nav-pills d-flex flex-column flex-fill">
                    <li className="nav-item">
                        <NavLink className="nav-link text-nowrap" to={RoutePaths.Manage.Profile.url()} exact={true}>
                            <FontAwesomeIcon icon={faUsers} /> {t('Sitemap:Profile')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link text-nowrap" to={RoutePaths.Manage.ChangePassword.url()}>
                            <FontAwesomeIcon icon={faKey} /> {t('Sitemap:ChangePassword')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link text-nowrap" to={RoutePaths.Manage.ExternalLogins.url()}>
                            <FontAwesomeIcon icon={faShareAlt} /> {t('Sitemap:ExternalLogins')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link text-nowrap" to={RoutePaths.Manage.MyPurchases.url()}>
                            <FontAwesomeIcon icon={faShoppingBag} /> {t('Sitemap:MyPurchases')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link text-nowrap" to={RoutePaths.Manage.PaymentInformation.url()}>
                            <FontAwesomeIcon icon={faCreditCard} /> {t('Sitemap:PaymentInformation')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link text-nowrap" to={RoutePaths.Manage.EmailSubscription.url()}>
                            <FontAwesomeIcon icon={faMailBulk} /> {t('Sitemap:EmailSubscription')}
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link text-nowrap" to={RoutePaths.Manage.DeleteAccount.url()}>
                            <FontAwesomeIcon icon={faTrash} /> {t('Sitemap:DeleteAccount')}
                        </NavLink>
                    </li>
                </ul>
            </nav>
        );
    }
}

export const SideMenu = withTranslation()(SideMenuComponent);
