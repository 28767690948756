import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { IAuthorizedRouteProps } from './AuthorizedRouteModels';
import { Spinner } from 'components/common/loaders';

class AuthorizedRouteComponent extends React.PureComponent<IAuthorizedRouteProps> {
    public componentDidMount() {
        const { onInit, isAuthenticated } = this.props;
        onInit(isAuthenticated);
    }

    public componentDidUpdate(prev: IAuthorizedRouteProps) {
        const { onInit, isAuthenticated } = this.props;
        const { isAuthenticated: prevIsAuthenticated } = prev;
        if (isAuthenticated !== prevIsAuthenticated) {
            onInit(isAuthenticated);
        }
    }

    public render() {
        const { ...otherProps } = this.props;
        const routeProps: RouteProps & { isAuthenticated?: unknown } = otherProps;
        delete routeProps.isAuthenticated;
        delete routeProps.component;
        delete routeProps.children;
        return (
            <Route {...routeProps}>
                {this.renderComponent()}
            </Route>
        );
    }

    public renderComponent() {
        const { isAuthenticated, component, children } = this.props;
        const CoercedWrappedComponent = component as React.ComponentType;
        return isAuthenticated ?
            component ?
                (
                    <CoercedWrappedComponent />
                ) :
                (
                    <>
                        {children}
                    </>
                ) :
            (
                <Spinner
                    loading={true}
                    size="big"
                    className="my-2 d-flex justify-content-center position-relative"
                    style={{ top: 'calc(50vh - 128px)' }}
                />
            );
    }
}

export const AuthorizedRoute = AuthorizedRouteComponent;
