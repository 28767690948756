
import { AppReducer } from 'store';

const unloadedState = () => {
    return {
        isSending: false as boolean,
    } as const;
};

export type ContactState = ReturnType<typeof unloadedState>;

export const ContactReducer: AppReducer<ContactState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@CONTACT/SEND_MESSAGE_REQUEST':
            return {
                ...state,
                isSending: true,
            };

        case '@CONTACT/SEND_MESSAGE_SUCCESS':
            return {
                ...state,
                isSending: false,
            };

        case '@CONTACT/SEND_MESSAGE_FAILURE':
            return {
                ...state,
                isSending: false,
            };

        default:
            return state;
    }
};
