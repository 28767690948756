import { RouterState, RouterAction } from 'connected-react-router';
import { Action as ReduxAction, Reducer } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
    reducer as OidcReducer,
    USER_EXPIRED,
    REDIRECT_SUCCESS,
    USER_LOADED,
    SILENT_RENEW_ERROR,
    SESSION_TERMINATED,
    USER_EXPIRING,
    USER_FOUND,
    LOADING_USER,
    LOAD_USER_ERROR,
    USER_SIGNED_OUT,
} from 'redux-oidc';

import { AboutAction } from './about/actions';
import { AboutReducer } from './about/reducer';
import { AppContextAction } from './appContext/actions';
import { AppContextReducer } from './appContext/reducer';
import { AuthenticationAction } from './authentication/actions';
import { AuthenticationReducer } from './authentication/reducer';
import { ContactAction } from './contact/actions';
import { ContactReducer } from './contact/reducer';
import { LegalAction } from './legal/actions';
import { LegalReducer } from './legal/reducer';
import { MyPurchasesAction } from './myPurchases/actions';
import { MyPurchasesReducer } from './myPurchases/reducer';
import { NormalizrAction } from './normalizr/actions';
import { NormalizrReducer } from './normalizr/reducer';
import { PaymentInformationAction } from './paymentInformation/actions';
import { PaymentInformationReducer } from './paymentInformation/reducer';
import { PressAction } from './press/actions';
import { PressReducer } from './press/reducer';
import { ProfileAction } from './profile/actions';
import { ProfileReducer } from './profile/reducer';
import { ValidationAction } from './validation/actions';
import { ValidationReducer } from './validation/reducer';

export const reducers = {
    about: AboutReducer,
    appContext: AppContextReducer,
    authentication: AuthenticationReducer,
    contact: ContactReducer,
    entities: NormalizrReducer,
    legal: LegalReducer,
    myPurchases: MyPurchasesReducer,
    oidc: OidcReducer,
    paymentInformation: PaymentInformationReducer,
    press: PressReducer,
    profile: ProfileReducer,
    validation: ValidationReducer,
};

type ReducersMapObject = typeof reducers;
type StateKeys = keyof ReducersMapObject;
export type ApplicationState = { readonly [key in StateKeys]: ReturnType<ReducersMapObject[key]> } & { router: RouterState };

const reduxOidcActionTypes = {
    'redux-oidc/REDIRECT_SUCCESS': REDIRECT_SUCCESS,
    'redux-oidc/USER_LOADED': USER_LOADED,
    'redux-oidc/SILENT_RENEW_ERROR': SILENT_RENEW_ERROR,
    'redux-oidc/SESSION_TERMINATED': SESSION_TERMINATED,
    'redux-oidc/USER_EXPIRING': USER_EXPIRING,
    'redux-oidc/USER_EXPIRED': USER_EXPIRED,
    'redux-oidc/USER_FOUND': USER_FOUND,
    'redux-oidc/LOADING_USER': LOADING_USER,
    'redux-oidc/LOAD_USER_ERROR': LOAD_USER_ERROR,
    'redux-oidc/USER_SIGNED_OUT': USER_SIGNED_OUT,
};
type ReduxOidcAction = { type: keyof typeof reduxOidcActionTypes };

export type Action =
    | AboutAction
    | AppContextAction
    | AuthenticationAction
    | ContactAction
    | LegalAction
    | MyPurchasesAction
    | NormalizrAction
    | PaymentInformationAction
    | PressAction
    | ProfileAction
    | ReduxOidcAction
    | RouterAction
    | ValidationAction;

export type AppReducer<S> = Reducer<S, Action>;
export type AppThunkAction<R = void> = ThunkAction<Promise<R>, ApplicationState, unknown, Action | ReduxAction>;
export type AppThunkDispatch = ThunkDispatch<ApplicationState, unknown, Action | ReduxAction>;
