import loadable from '@loadable/component';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Route, Switch, RouteComponentProps, matchPath, withRouter } from 'react-router-dom';
import { TermType } from 'services/api/ApiClient';
import { AuthorizedRoute } from './AuthorizedRoute';
import { RoutePaths } from './RoutePaths';
import { Spinner } from 'components/common/loaders';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { MetaData } from 'components/common/MetaData';
import { withManagePageLayout } from 'components/Pages/Manage/Layout';

const About = loadable(() => import('components/Pages/About'), {
    resolveComponent: components => components.About,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Callback = loadable(() => import('components/Pages/Callback'), {
    resolveComponent: components => components.Callback,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Contact = loadable(() => import('components/Pages/Contact'), {
    resolveComponent: components => components.Contact,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const NotFound = loadable(() => import('components/Pages/Errors'), {
    resolveComponent: components => components.NotFound,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const OidcError = loadable(() => import('components/Pages/Errors'), {
    resolveComponent: components => components.OidcError,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const ForgottenPassword = loadable(() => import('components/Pages/ForgottenPassword'), {
    resolveComponent: components => components.ForgottenPassword,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Help = loadable(() => import('components/Pages/Help'), {
    resolveComponent: components => components.Help,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Privacy = loadable(() => import('components/Pages/Legal/Privacy'), {
    resolveComponent: components => components.Privacy,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Terms = loadable(() => import('components/Pages/Legal/Terms'), {
    resolveComponent: components => components.Terms,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Login = loadable(() => import('components/Pages/Login'), {
    resolveComponent: components => components.Login,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Logout = loadable(() => import('components/Pages/Logout'), {
    resolveComponent: components => components.Logout,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const DeleteAccount = loadable(() => import('components/Pages/Manage/DeleteAccount'), {
    resolveComponent: components => components.DeleteAccount,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const EmailSubscriptions = loadable(() => import('components/Pages/Manage/EmailSubscriptions'), {
    resolveComponent: components => components.EmailSubscriptions,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const ExternalLogins = loadable(() => import('components/Pages/Manage/ExternalLogins'), {
    resolveComponent: components => components.ExternalLogins,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const MyPurchases = loadable(() => import('components/Pages/Manage/MyPurchases'), {
    resolveComponent: components => components.MyPurchases,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const PaymentInformation = loadable(() => import('components/Pages/Manage/PaymentInformation'), {
    resolveComponent: components => components.PaymentInformation,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Password = loadable(() => import('components/Pages/Manage/Password'), {
    resolveComponent: components => components.Password,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Press = loadable(() => import('components/Pages/Press'), {
    resolveComponent: components => components.Press,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Profile = loadable(() => import('components/Pages/Manage/Profile'), {
    resolveComponent: components => components.Profile,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Pricing = loadable(() => import('components/Pages/Pricing'), {
    resolveComponent: components => components.Pricing,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const Register = loadable(() => import('components/Pages/Register'), {
    resolveComponent: components => components.Register,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});
const ResetPassword = loadable(() => import('components/Pages/ResetPassword'), {
    resolveComponent: components => components.ResetPassword,
    fallback: <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center" />,
});

class LocalizedRoutesComponent extends React.PureComponent<WithTranslation & RouteComponentProps> {
    public render() {
        const { i18n, location } = this.props;
        const match = matchPath<{ lang: string }>(location.pathname, {
            path: '/:lang/*',
        });
        const localesLoading = match && match.params.lang.toLowerCase() !== i18n.language.toLowerCase();
        return localesLoading ? (
            <Spinner loading={true} size="big" className="my-2 d-flex justify-content-center position-relative" style={{ top: 'calc(50vh - 128px)' }} />
        ) :
            (
                <>
                    <ErrorBoundary>
                        <MetaData currentUrl={location.pathname} />
                    </ErrorBoundary>
                    <FragmentSupportingSwitch>
                        {this.createLocalizedRoutes(i18n.language)}
                        <Route path="/callback" component={Callback} />
                        <Route path={RoutePaths.Errors.NotFound.route()} component={NotFound} />
                    </FragmentSupportingSwitch>
                </>
            );
    }

    private createLocalizedRoutes(language: string) {
        return (
            <React.Fragment>
                <Route exact={true} path={RoutePaths.About.route(language)} component={About} />
                <Route exact={true} path={RoutePaths.Account.Error.route(language)} component={OidcError} />
                <Route exact={true} path={RoutePaths.Account.ForgottenPassword.route(language)} component={ForgottenPassword} />
                <Route exact={true} path={RoutePaths.Account.Login.route(language)} component={Login} />
                <Route exact={true} path={RoutePaths.Account.Logout.route(language)} component={Logout} />
                <Route exact={true} path={RoutePaths.Account.Register.route(language)} component={Register} />
                <Route exact={true} path={RoutePaths.Account.ResetPassword.route(language)} component={ResetPassword} />
                <Route exact={true} path={RoutePaths.Contact.route(language)} component={Contact} />
                <Route exact={true} path={RoutePaths.Help.route(language)} component={Help} />
                <Route exact={true} path={RoutePaths.Legal.LegalNotices.route(language)}>
                    <Terms termType={TermType.WwwMeshotesComLegalNotices} />
                </Route>
                <Route exact={true} path={RoutePaths.Legal.Privacy.route(language)} component={Privacy} />
                <Route exact={true} path={RoutePaths.Legal.TermsOfSales.route(language)}>
                    <Terms termType={TermType.WwwMeshotesComTermsOfSales} />
                </Route>
                <Route exact={true} path={RoutePaths.Legal.TermsOfSalesPro.route(language)}>
                    <Terms termType={TermType.ProMeshotesComTermsOfSales} />
                </Route>
                <Route exact={true} path={RoutePaths.Legal.TermsOfUse.route(language)}>
                    <Terms termType={TermType.WwwMeshotesComTermsOfUse} />
                </Route>
                <Route exact={true} path={RoutePaths.Legal.TermsOfUsePro.route(language)}>
                    <Terms termType={TermType.ProMeshotesComTermsOfUse} />
                </Route>
                <AuthorizedRoute exact={true} path={RoutePaths.Manage.ChangePassword.route(language)} component={withManagePageLayout(Password)} />
                <AuthorizedRoute exact={true} path={RoutePaths.Manage.DeleteAccount.route(language)} component={withManagePageLayout(DeleteAccount)} />
                <AuthorizedRoute exact={true} path={RoutePaths.Manage.EmailSubscription.route(language)} component={withManagePageLayout(EmailSubscriptions)} />
                <AuthorizedRoute exact={true} path={RoutePaths.Manage.ExternalLogins.route(language)} component={withManagePageLayout(ExternalLogins)} />
                <AuthorizedRoute exact={true} path={RoutePaths.Manage.MyPurchases.route(language)} component={withManagePageLayout(MyPurchases)} />
                <AuthorizedRoute exact={true} path={RoutePaths.Manage.PaymentInformation.route(language)} component={withManagePageLayout(PaymentInformation)} />
                <AuthorizedRoute exact={true} path={['/', RoutePaths.Manage.Profile.route()]} component={withManagePageLayout(Profile)} />
                <Route exact={true} path={RoutePaths.Press.route(language)} component={Press} />
                <Route exact={true} path={RoutePaths.ServicesPricing.route(language)} component={Pricing} />
            </React.Fragment>
        );
    }
}

function FragmentSupportingSwitch({ children }: { children: React.ReactNode }) {
    const flattenedChildren: React.ReactNode[] = [];
    flatten(flattenedChildren, children);
    return (
        <Switch>
            {flattenedChildren}
        </Switch>
    );
}

function flatten(target: React.ReactNode[], children: React.ReactNode) {
    React.Children.forEach(children, (child) => {
        if (React.isValidElement(child)) {
            if (child.type === React.Fragment) {
                flatten(target, (child as { props: { children?: React.ReactNode } }).props.children);
            } else {
                target.push(child);
            }
        }
    });
}

export const LocalizedRoutes = withRouter(withTranslation()(LocalizedRoutesComponent));