import { AppReducer } from 'store';

const unloadedState = () => {
    return {
        didInvalidate: true as boolean,
        isFetching: false as boolean,
        pressId: '' as string,
    } as const;
};

export type PressState = ReturnType<typeof unloadedState>;

export const PressReducer: AppReducer<PressState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@PRESS/GET_PRESS_REQUEST':
            return {
                ...state,
                isFetching: true,
            };

        case '@PRESS/GET_PRESS_SUCCESS':
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                pressId: action.pressId,
            };

        case '@PRESS/GET_PRESS_FAILURE':
            return {
                ...state,
                isFetching: false,
                didInvalidate: true,
            };

        default:
            return state;
    }
};
