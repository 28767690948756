import { ErrorBoundary } from 'components/common/ErrorBoundary';
import * as React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { Footer } from './footer/Footer';
import { NavBar } from './nav';
import { CookieConsent } from './CookieConsent';
import { TermsConsent } from './TermsConsent';

export const AppLayout: React.FC = (props) => (
    <React.Fragment>
        <NavBar />
        <div id="body" className="body-content">
            <ErrorBoundary>
                {props.children}
            </ErrorBoundary>
        </div>
        <Footer />
        <CookieConsent />
        <TermsConsent />
        <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            preventDuplicates={true}
            position="top-right"
            transitionIn="bounceIn"
            transitionOut="bounceOut"
            progressBar={true}
            closeOnToastrClick={true}
        />
    </React.Fragment>
);
