import { noop } from 'lodash-es';
import { connect } from 'react-redux';
import { AppThunkDispatch, ApplicationState } from 'store';
import { getAppContext } from 'store/appContext/thunk';
import { Selectors } from 'store/normalizr/selectors';
import { NavBar } from './NavBar';
import { IDispatchProps, IProps } from './NavBarModels';

const mapStateToProps = (state: ApplicationState): IProps => {
    const appContext = Selectors.getAppContext(state.appContext.appContextId, state);
    return {
        isAuthenticated: !!(appContext?.loggedUser),
        loggedUser: appContext?.loggedUser || undefined,
        isGuest: !!(appContext?.loggedGuest),
    };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): IDispatchProps => {
    return {
        onInit: () => {
            dispatch(getAppContext()).catch(noop);
        },
    };
};

export const NavBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavBar);
