import { noop } from 'lodash-es';
import { connect } from 'react-redux';
import { signIn, signOut } from 'services/AuthService';
import { AppThunkDispatch, ApplicationState } from 'store';
import { getAppContext } from 'store/appContext/thunk';
import { Selectors } from 'store/normalizr/selectors';
import { UserMenu } from './UserMenu';
import { IDispatchProps, IProps } from './UserMenuModels';
import { AspNetRoleType } from 'services/api/ApiClient';

const mapStateToProps = (state: ApplicationState): IProps => {
    const appContext = Selectors.getAppContext(state.appContext.appContextId, state);
    return {
        isAuthenticated: !!(appContext?.loggedUser),
        loggedUser: appContext?.loggedUser || undefined,
        isGuest: !!(appContext?.loggedGuest),
        loggedGuest: appContext?.loggedGuest || undefined,
        isProManager: !!(appContext?.loggedUser?.roles?.includes(AspNetRoleType.Pro)),
        userIdToken: state.oidc.user && state.oidc.user.id_token,
    };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): IDispatchProps => {
    return {
        onInit: () => {
            dispatch(getAppContext()).catch(noop);
        },
        onSignIn: () => {
            signIn();
        },
        onSignOut: (userIdToken) => {
            signOut(userIdToken);
        },
    };
};

export const UserMenuContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserMenu);
