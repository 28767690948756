import { noop } from 'lodash-es';
import { batch, connect } from 'react-redux';
import { AppThunkDispatch, ApplicationState } from 'store';
import { getAppContext } from 'store/appContext/thunk';
import { Selectors } from 'store/normalizr/selectors';
import { SideMenu } from './SideMenu';
import { IDispatchProps, IProps } from './SideMenuModels';

const mapStateToProps = (state: ApplicationState): IProps => {
    const appContext = Selectors.getAppContext(state.appContext.appContextId, state);

    return {
        isAuthenticated: !!(appContext?.loggedUser),
        loggedUser: appContext?.loggedUser || undefined,
        isGuest: !!(appContext?.loggedGuest),
    };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): IDispatchProps => {
    return {
        onInit: () => {
            batch(() => {
                dispatch(getAppContext()).catch(noop);
            });
        },
    };
};

export const SideMenuContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideMenu);
