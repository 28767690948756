import { AppReducer } from 'store';
import { IRequest } from 'store/SharedModels';

export interface IEmailAvailability extends IRequest {
    readonly isAvailable?: boolean;
}

const unloadedState = () => {
    return {
        emailsAvailabilities: {} as { readonly [email: string]: IEmailAvailability },
    } as const;
};

export type ValidationState = ReturnType<typeof unloadedState>;

export const ValidationReducer: AppReducer<ValidationState> = (state = unloadedState(), action) => {
    switch (action.type) {
        case '@VALIDATION/IS_EMAIL_AVAILABLE_REQUEST':
            return {
                ...state,
                emailsAvailabilities: {
                    ...state.emailsAvailabilities,
                    [action.email]: {
                        ...state.emailsAvailabilities[action.email],
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@VALIDATION/IS_EMAIL_AVAILABLE_SUCCESS':
            return {
                ...state,
                emailsAvailabilities: {
                    ...state.emailsAvailabilities,
                    [action.email]: {
                        ...state.emailsAvailabilities[action.email],
                        didInvalidate: false,
                        isFetching: false,
                        isAvailable: action.isAvailable,
                    },
                },
            };

        case '@VALIDATION/IS_EMAIL_AVAILABLE_FAILURE':
            return {
                ...state,
                emailsAvailabilities: {
                    ...state.emailsAvailabilities,
                    [action.email]: {
                        ...state.emailsAvailabilities[action.email],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
