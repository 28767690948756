import i18n from 'i18next';
import { RouterService } from 'services/RouterService';
import { windowIfDefined } from 'services/WindowService';
import { userManager } from './UserManager';
import { getBaseUrl } from 'config/constants';
import { store } from 'configureStore';
import { createInitSignOutAction, createInitSignInAction, createInitSignInCompleteAction } from 'store/authentication/actions';
import { loadUser as oidcLoadUser } from 'redux-oidc';
import { AppThunkDispatch } from 'store';
import { getAppContext } from 'store/appContext/thunk';
import { noop } from 'lodash-es';

export interface IAuthInfo {
  accessToken?: string | null;
  accessTokenExpirationDate?: Date | undefined;
  refreshToken?: string | null;
}

export const getAuthInfo = async (): Promise<IAuthInfo> => {
  const user = await userManager.getUser();
  return {
    accessToken: user?.access_token,
    accessTokenExpirationDate: user?.expires_at ? new Date(user.expires_at) : undefined,
    refreshToken: user?.refresh_token,
  };
};

export const signIn = () => {
  return canSignIn()
    .then(() => {
      return signInSilent()
        .catch(() => {
          return userManager.signinRedirect({
            state: window.location.pathname,
            ui_locales: i18n.language,
          });
        });
    });
};

export const signInSilent = () => {
  return canSignIn()
    .then(() => {
      store?.dispatch(createInitSignInAction());

      return userManager
        .signinSilent({
          state: window.location.pathname,
          ui_locales: i18n.language,
        })
        .then(() => {
          oidcLoadUser(store, userManager)
            .then(() => {
              return (store?.dispatch as AppThunkDispatch)(getAppContext());
            })
            .catch(noop);
        })
        .finally(() => {
          store?.dispatch(createInitSignInCompleteAction());
        });
    });
};

export const redirect = (redirectUrl: string) => {
  if (windowIfDefined) {
    windowIfDefined.location.replace(redirectUrl);
  } else {
    RouterService.staticContext.url = redirectUrl;
  }
};

export const signOut = (userIdToken?: string) => {
  store?.dispatch(createInitSignOutAction());
  userManager.stopSilentRenew();
  return userManager.signoutRedirect({
    ui_locales: i18n.language,
    id_token_hint: userIdToken,
    post_logout_redirect_uri: `${getBaseUrl()}/${i18n.language}/${i18n.t('Routing:Login')}`,
  });
};

export const loadUser = () => {
  return oidcLoadUser(store, userManager)
    .then(user => {
      if (user) {
        return userManager.querySessionStatus()
          .then(session => {
            if (session && session.sub == user.profile.sub) {
              return signInSilent();
            } else {
              return userManager.removeUser();
            }
          })
          .catch(() => {
            return userManager.removeUser();
          });
      } else {
        return userManager.querySessionStatus()
          .then(session => {
            if (session && session.sub) {
              return signInSilent();
            }
          });
      }
    });
};

const canSignIn = () => {
  if (!windowIfDefined) {
    return Promise.reject('SSR cannot sign in');
  }

  const isSigningOut = store?.getState().authentication.isSigningOut;
  if (isSigningOut) {
    // Do not cancel sign out by calling signIn
    return Promise.reject('Is signing out');
  }

  if (store?.getState().authentication.isSigningIn) {
    // Do not cancel sign out by calling signIn
    return Promise.reject('Is signing in');
  }

  return Promise.resolve();
};
