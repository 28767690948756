import {
    LogoutRequest,
    PaymentInformation,
    PressCommunique,
    ProfileInfo,
    UserLoginInfo,
} from './ApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './ApiSchema';

export class BaseApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.LogoutRequest:
                return `${(value as LogoutRequest).sessionId}` || '-';

            case ObjectsWithoutIdPropertyEnum.PaymentInformation:
                return `${(value as PaymentInformation).userId}` || '-';

            case ObjectsWithoutIdPropertyEnum.PressCommunique:
                return `${(value as PressCommunique).url}` || '-';

            case ObjectsWithoutIdPropertyEnum.ProfileInfo:
                return `${(value as ProfileInfo).userId}` || '-';

            case ObjectsWithoutIdPropertyEnum.UserLoginInfo:
                return (value as UserLoginInfo).loginProvider || '-';
        }

        const exhaustiveCheck: never = type;
        return exhaustiveCheck;
    }
}
