import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { boundMethod } from 'autobind-decorator';
import { RoutePaths } from 'components/Routes/RoutePaths';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { IUserMenuProps } from './UserMenuModels';
import { NavLink } from 'react-router-dom';
import { getConfiguration } from 'config/constants';

class UserMenuComponent extends React.PureComponent<IUserMenuProps & WithTranslation> {
    public componentDidMount() {
        const { onInit } = this.props;
        onInit();
    }

    public render() {
        return (
            <>
                <ul className="nav nav-pills">
                    {this.renderAuthenticatedMenu()}
                    {this.renderAnonymousMenu()}
                </ul>
                {this.renderProMenu()}
            </>
        );
    }

    private renderAuthenticatedMenu() {
        const { isAuthenticated, t, loggedUser } = this.props;
        return isAuthenticated ? (
            <li className="nav-item">
                <UncontrolledDropdown inNavbar={true} className="d-flex">
                    <DropdownToggle caret={true} color="" className="nav-link d-none d-md-inline flex-grow-1">
                        {t('Common:Hi__0__', { 0: loggedUser ? loggedUser.userName : '' })}
                    </DropdownToggle>
                    <DropdownToggle caret={true} color="" className="nav-link d-md-none flex-grow-1">
                        {t('Common:Hi')}
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                        {this.renderUserMenu()}
                        {this.renderGuestMenu()}
                        <div className="dropdown-divider" />
                        <DropdownItem onClick={this.handleLogout} className="nav-link">
                            <FontAwesomeIcon icon={faPowerOff} className="pull-right" /> {t('Common:Logout')}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </li>
        ) : null;
    }

    private renderUserMenu() {
        const { isAuthenticated, t } = this.props;
        return isAuthenticated ? (
            <React.Fragment>
                <NavLink to={RoutePaths.Manage.Profile.url()} exact={true} role="menuitem" className="dropdown-item nav-link">
                    {t('Account:Manage_account')}
                </NavLink>
                <NavLink to={RoutePaths.Manage.MyPurchases.url()} role="menuitem" className="dropdown-item nav-link">
                    {t('Sitemap:MyPurchases')}
                </NavLink>
                <NavLink to={RoutePaths.Manage.PaymentInformation.url()} role="menuitem" className="dropdown-item nav-link">
                    {t('Sitemap:PaymentInformation')}
                </NavLink>
                <NavLink to={RoutePaths.Manage.EmailSubscription.url()} role="menuitem" className="dropdown-item nav-link">
                    {t('Sitemap:EmailSubscription')}
                </NavLink>
            </React.Fragment>
        ) : null;
    }

    private renderGuestMenu() {
        const { isGuest, t } = this.props;
        return isGuest ? (
            <NavLink to={RoutePaths.Account.Register.url()} className="nav-link">
                {t('Account:ExternalLoginConfirmation_Execute_Register')}
            </NavLink>
        ) : null;
    }

    private renderProMenu() {
        const { isProManager, t } = this.props;
        const configuration = getConfiguration();
        return isProManager ? (
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <a href={configuration.externalLinks.mesHotesPro} className="nav-link">
                        {t('Account:ProAccess')}
                    </a>
                </li>
            </ul>
        ) : null;
    }

    private renderAnonymousMenu() {
        const { isAuthenticated, t } = this.props;
        return !isAuthenticated ? (
            <React.Fragment>
                <li className="nav-item">
                    <NavLink to={RoutePaths.Account.Register.url()} className="nav-link">
                        {t('Account:ExternalLoginConfirmation_Execute_Register')}
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to={RoutePaths.Account.Login.url()}
                        className="nav-link"
                        activeClassName="active"
                        isActive={(_, location) => location.pathname.includes(RoutePaths.Account.Login.url())}
                        onClick={this.handleLogin}
                    >
                        {t('Account:Log_in')}
                    </NavLink>
                </li>
            </React.Fragment>
        ) : null;
    }

    @boundMethod
    private handleLogin(e: React.MouseEvent<HTMLAnchorElement>): void {
        const { onSignIn } = this.props;
        e.preventDefault();
        if (!(e.target as HTMLAnchorElement).classList.contains('active')) {
            onSignIn();
        }
    }

    @boundMethod
    private handleLogout(e: React.MouseEvent<HTMLAnchorElement>): void {
        const { onSignOut, userIdToken } = this.props;
        e.preventDefault();
        onSignOut(userIdToken);
    }
}

export const UserMenu = withTranslation()(UserMenuComponent);
