import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { boundMethod } from 'autobind-decorator';
import { RoutePaths } from 'components/Routes/RoutePaths';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ITermsConsentProps } from './TermsConsentModels';
import { NavLink } from 'react-router-dom';
import { TermType } from 'services/api/ApiClient';

class TermsConsentComponent extends React.PureComponent<ITermsConsentProps & WithTranslation> {
    public componentDidMount() {
        const { onInit } = this.props;
        onInit();
    }

    public render() {
        const { termsStatus } = this.props;
        return termsStatus && (termsStatus.showTermsOfUse || termsStatus.showTermsOfUsePro) ? (
            <>
                {termsStatus.showTermsOfUse ? this.renderTermsOfUseConsentRequest(RoutePaths.Legal.TermsOfUse.url(), this.handleAccept) : null}
                {termsStatus.showTermsOfUsePro ? this.renderTermsOfUseConsentRequest(RoutePaths.Legal.TermsOfUsePro.url(), this.handleAcceptPro) : null}
            </>
        ) : null;
    }

    private renderTermsOfUseConsentRequest(termsUrl: string, acceptCalllback: () => void) {
        const { t } = this.props;
        return (
            <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light" role="alert">
                <span className="navbar-brand">
                    <FontAwesomeIcon icon={faInfoCircle} aria-hidden="true" />
                </span>
                <div className="navbar-text d-lg-none">
                    {t('Account:TermsOfUse_cshtml_Title')}
                </div>

                <div className="d-flex flex-fill align-items-center justify-content-between">
                    <div className="navbar-text">
                        {t('Account:TermsOfUse_cshtml_Lead')}
                    </div>
                    <div>
                        <NavLink className="btn btn-info mx-2" to={termsUrl}>
                            {t('Common:CookieReadMore')}
                        </NavLink>
                        <button className="btn btn-outline-success mx-2" onClick={acceptCalllback}>
                            {t('Common:CookieAcceptButton')}
                        </button>
                    </div>
                </div>
            </nav>
        );
    }

    @boundMethod
    private handleAccept(): void {
        const { onAcceptTerms } = this.props;
        onAcceptTerms(TermType.WwwMeshotesComTermsOfUse);
    }

    @boundMethod
    private handleAcceptPro(): void {
        const { onAcceptTerms } = this.props;
        onAcceptTerms(TermType.ProMeshotesComTermsOfUse);
    }
}

export const TermsConsent = withTranslation()(TermsConsentComponent);
